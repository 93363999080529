// AdminDashboardPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "../../styles/AdminDashboardPage.css"; // Import the external CSS file
import "../../styles/AdminCard.css"; // Import the external CSS file
import { Spinner } from "react-bootstrap";
import axios from "axios"; // Import axios

import { API_GATEWAY_URL } from "../../constants";
import Layout from "../Layout";
import AdminCard from "./AdminCard"; // Import the new component
import LoadingSpinner from "../LoadingSpinner";
import Swal from "sweetalert2";

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [workerCount, setWorkerCount] = useState(0);
  const [invitationCount, setInvitationCount] = useState(0);
  const [missionCount, setMissionCount] = useState(0);
  const [pendingValidationCount, setPendingValidationCount] = useState(0); // Placeholder for additional count
  const [completedMissionCount, setCompletedMissionCount] = useState(0); // Placeholder for additional count
  const [isProfileComplete, setIsProfileComplete] = useState(true);
  const [pendingRequestCount, setPendingRequestCount] = useState(0); // State to hold the count of pending requests
  // for the model's management
  const [options, setOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [currentModelID, setCurrentModelID] = useState(null);

  const [config, setConfig] = useState({
    ia_client_search: true,
    ia_profile_search: true,
    cv_extraction_enabled: true,
    linkedin_extraction_enabled: true,
  });

  const [recipients, setRecipients] = useState([]);
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      console.log(user);
      if (!user) {
        setError("Aucun utilisateur connecté");
        setLoading(false);
        navigate("/admin-login");
        return;
      }
      const token = user.access_token;

      try {
        // Fetch client requests
        const response = await axios.get(
          `${API_GATEWAY_URL}/admin-client-demandes/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Filter requests that are pending
        const pendingRequests = response.data.filter((demande) =>
          demande.statut.startsWith("W")
        );
        setPendingRequestCount(pendingRequests.length);

        // Other fetch operations here...
        axios
          .get(`${API_GATEWAY_URL}/llm-parameters/get-all-llms`)
          .then((response) => {
            setOptions(response.data["llms"]);
            const activeModel = response.data["llms"].find(
              (model) => model.actif_model
            );
            if (activeModel) {
              setSelectedModel(activeModel.model_name);
              setSelectedVersion(activeModel.model_version);
              setCurrentModelID(activeModel.model_id);
            }
            // setLoading(false);
          })
          .catch((error) => {
            setError("An error occurred while fetching the LLMs");
            // setLoading(false);
          });
        //get actual global config
        axios
          .get(`${API_GATEWAY_URL}/config/`)
          .then((response) => {
            setConfig(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching config:", error);
            setError("Failed to load configuration");
            setLoading(false);
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
      const API_URL = `${API_GATEWAY_URL}/recipients`;
      axios
        .get(API_URL)
        .then((response) => setRecipients(response.data))
        .catch((error) =>
          console.error("Erreur lors de la récupération :", error)
        );
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: checked }));
  };

  const handleSave = () => {
    axios
      .put(`${API_GATEWAY_URL}/config/`, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Modifications validées avec succès!",
          confirmButtonText: "OK",
          customClass: {
            popup: "swal2-front",
          },
        });
      })
      .catch((error) => {
        console.error("Error saving config:", error);
        alert("Failed to save configuration");
      });
  };

  const handleAddRecipient = () => {
    if (!newEmail) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Veuillez saisir une adresse e-mail.",
      });
      return;
    }
    const API_URL = `${API_GATEWAY_URL}/recipients/`;
    axios
      .post(API_URL, { email: newEmail })
      .then((response) => {
        setRecipients([...recipients, response.data]);
        setNewEmail("");
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Destinataire ajouté avec succès!",
        });
      })
      .catch((error) => {
        console.error("Erreur lors de l'ajout :", error);
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: `Impossible d'ajouter le destinataire`,
        });
      });
  };

  // Supprimer un destinataire
  const handleDeleteRecipient = (id) => {
    const API_URL = `${API_GATEWAY_URL}/recipients`;
    axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        setRecipients(recipients.filter((recipient) => recipient.id !== id));
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Destinataire supprimé avec succès!",
        });
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression :", error);
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Impossible de supprimer le destinataire.",
        });
      });
  };

  // Function to extract the initials
  const getInitials = (name) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("");

  // Managing the Models

  const handle_model_change = async (event) => {
    const newModelName = event.target.value;
    setSelectedModel(newModelName);
    setSelectedVersion(null);
    const id = getModelIdByName(newModelName);
    if (id) {
      setCurrentModelID(id);
    }
  };

  const handle_version_change = async (event) => {
    const newVersion = event.target.value;
    setSelectedVersion(newVersion);
    const id = getModelIdByVersion(newVersion);
    if (id) {
      setCurrentModelID(id);
    }
  };

  const handleSetCurrentModel = async (event) => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.get(
        `${API_GATEWAY_URL}/llm-parameters/get-preferred-llm`
      );
      setSelectedModel(result.data["model_name"]);
      setSelectedVersion(result.data["model_version"]);
      if (getModelIdByName(result.data["model_name"])) {
        setCurrentModelID(getModelIdByName(result.data["model_name"]));
      } else {
        setCurrentModelID(getModelIdByVersion(result.data["model_version"]));
      }
    } catch (err) {
      setError("An error occurred while changin the LLM");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitModel = async (event) => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.post(
        `${API_GATEWAY_URL}/llm-parameters/update-actif-llm`,
        {
          activated_id: currentModelID,
        }
      );
    } catch (err) {
      setError("An error occurred while changin the LLM");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getModelIdByName = (modelName) => {
    const selectedOption = options.find(
      (option) =>
        option.model_name === modelName && option.model_version === null
    );
    return selectedOption ? selectedOption.model_id : null;
  };

  const getModelIdByVersion = (modelVersion) => {
    const selectedOption = options.find(
      (option) =>
        option.model_name === selectedModel &&
        option.model_version === modelVersion
    );
    return selectedOption ? selectedOption.model_id : null;
  };

  const uniqueModels = [...new Set(options.map((option) => option.model_name))];
  const versions = options
    .filter(
      (option) =>
        option.model_name === selectedModel && option.model_version !== null
    )
    .map((option) => option.model_version);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  return (
    <div className="pw-page-layout ">
      <AdminCard />

      <div className="missions-and-team-container">
        <div className="section-container mission-section">
          <h2>Mes demandes</h2>
          <div className="pw-additional-info">
            <div className="pw-card pw-card-info">
              <div className="pw-card-title">Demandes en attente</div>
              <div className="pw-card-count">{pendingRequestCount}</div>{" "}
              {/* Display the pending requests count */}
            </div>
            <div className="pw-card pw-card-pending">
              <div className="pw-card-title">Demandes en cours</div>
              <div className="pw-card-count"></div>
            </div>
            <div className="pw-card pw-card-pending">
              <div className="pw-card-title">
                Demandes en attente d'évaluation
              </div>
              <div className="pw-card-count"></div>
            </div>
            <div className="pw-card pw-card-mission">
              <div className="pw-card-title">Demandes terminées</div>
              <div className="pw-card-count"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-[80%]">
          {/* Global Configuration */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="mb-6">
              <h2 className="text-xl font-bold text-gray-900">
                Configuration Globale
              </h2>
            </div>
            <div className="space-y-4">
              <div className="space-y-4">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={config.ia_client_search}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "ia_client_search",
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  <span className="text-gray-700">
                    Activer la recherche de compétences par l'IA (Client)
                  </span>
                </label>

                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={config.ia_profile_search}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "ia_profile_search",
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  <span className="text-gray-700">
                    Activer la recherche de compétences par l'IA (autres
                    profiles)
                  </span>
                </label>

                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={config.cv_extraction_enabled}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "cv_extraction_enabled",
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  <span className="text-gray-700">
                    Activer l'extraction de données à partir des CV
                  </span>
                </label>

                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={config.linkedin_extraction_enabled}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "linkedin_extraction_enabled",
                          checked: e.target.checked,
                        },
                      })
                    }
                  />
                  <span className="text-gray-700">
                    Activer l'extraction de données à partir de LinkedIn
                  </span>
                </label>
              </div>
              <button
                onClick={handleSave}
                className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save Configuration
              </button>
            </div>
          </div>

          {/* Model Selection */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="mb-6">
              <h2 className="text-xl font-bold text-gray-900">
                Model Selection
              </h2>
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select Model
                </label>
                <select
                  value={selectedModel || ""}
                  onChange={(e) => handle_model_change(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                >
                  <option value="">Select a model</option>
                  {uniqueModels.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select Version
                </label>
                <select
                  value={selectedVersion || ""}
                  onChange={(e) => handle_version_change(e.target.value)}
                  disabled={!selectedModel || versions.length === 0}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md disabled:bg-gray-100"
                >
                  <option value="">Select a version</option>
                  {versions.map((version) => (
                    <option key={version} value={version}>
                      {version}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <button
                  onClick={handleSubmitModel}
                  disabled={
                    !selectedModel || (versions.length > 0 && !selectedVersion)
                  }
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  Apply Changes
                </button>
                <button
                  onClick={handleSetCurrentModel}
                  className="w-full bg-gray-100 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Set to Current
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Recipients Management */}
        <div className="bg-white mt-8 rounded-lg shadow p-6 w-[60%]">
          <div className="mb-6">
            <h2 className="text-xl font-bold text-gray-900">
              Gestion des Destinataires
            </h2>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddRecipient();
            }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Adresse e-mail
              </label>
              <div className="flex space-x-2 mt-1">
                <input
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Entrez une adresse e-mail"
                  className="flex-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <button
                  type="submit"
                  className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </form>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">
              Liste des Destinataires
            </h3>
            <div className="space-y-2">
              {recipients.map((recipient) => (
                <div
                  key={recipient.id}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <span className="text-gray-700">{recipient.email}</span>
                  <button
                    onClick={() => handleDeleteRecipient(recipient.id)}
                    className="bg-red-600 text-white py-1 px-3 rounded-md text-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Supprimer
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
