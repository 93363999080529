// import React, { useState, useEffect } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
// import { API_GATEWAY_URL } from "../constants";
// import AdminCard from "./admin/AdminCard";
// import {
//   Checkbox,
//   Modal,
//   Box,
//   Typography,
//   Button,
//   Paper,
//   CircularProgress,
// } from "@mui/material";

// const BpDemandes = () => {
//   const [entities, setEntities] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedEntities, setSelectedEntities] = useState(null);
//   const [openModal, setOpenModal] = useState(false);
//   const [selectedRequest, setSelectedRequest] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${API_GATEWAY_URL}/businesspartnerrequests/`
//         );
//         setEntities(response.data);
//       } catch (error) {
//         console.error("Erreur lors de la récupération des données : ", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleOpenModal = (request) => {
//     setSelectedRequest(request);
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setSelectedRequest(null);
//   };

//   const handleAcceptRequest = () => {
//     console.log("Demande acceptée : ", selectedRequest);
//     handleCloseModal();
//   };

//   const columns = [
//     {
//       field: "select",
//       headerName: "Sélectionné",
//       width: 80,
//       renderCell: (params) => (
//         <Checkbox
//           checked={selectedEntities === params.row.id_bp}
//           sx={{
//             "&.Mui-checked": {
//               color: "#1976d2",
//             },
//           }}
//         />
//       ),
//     },
//     { field: "id_bp", headerName: "ID", width: 150 },
//     { field: "prenom", headerName: "Prénom", width: 150 },
//     { field: "nom", headerName: "Nom", width: 150 },
//     { field: "email", headerName: "Email", width: 200 },
//     {
//       field: "numero_de_telephone",
//       headerName: "Téléphone",
//       width: 150,
//     },
//     {
//       field: "details",
//       headerName: "Actions",
//       width: 120,
//       renderCell: (params) => (
//         <Button
//           variant="contained"
//           size="small"
//           sx={{
//             backgroundColor: "#1976d2",
//             "&:hover": {
//               backgroundColor: "#1565c0",
//             },
//           }}
//           onClick={() => handleOpenModal(params.row)}
//         >
//           Détails
//         </Button>
//       ),
//     },
//   ];

//   const customDataGridStyles = {
//     "& .MuiDataGrid-root": {
//       border: "none",
//       backgroundColor: "white",
//       borderRadius: "8px",
//       boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
//     },
//     "& .MuiDataGrid-columnHeaders": {
//       backgroundColor: "#f8fafc",
//       borderBottom: "1px solid #e2e8f0",
//     },
//     "& .MuiDataGrid-cell": {
//       borderBottom: "1px solid #f1f5f9",
//     },
//     "& .MuiDataGrid-row:hover": {
//       backgroundColor: "#f8fafc",
//     },
//   };

//   const modalStyle = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "90%",
//     maxWidth: 500,
//     bgcolor: "background.paper",
//     borderRadius: "8px",
//     boxShadow: 24,
//     p: 4,
//   };

//   const detailRowStyle = {
//     display: "grid",
//     gridTemplateColumns: "120px 1fr",
//     gap: "16px",
//     marginBottom: "16px",
//   };

//   return (
//     <>
//       <AdminCard />
//       <div className="w-full flex justify-center items-center">
//         <div className="w-[75%] flex flex-col items-center justify-center ml-[18%]">
//           <div className="w-full flex justify-start">
//             <h2 className="text-[#10316B] font-poppins font-bold text-2xl mt-16">
//               Demandes BPs
//             </h2>
//           </div>
//           <div className="w-full flex justify-end space-x-2 mt-8">
//             <div className="bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer">
//               <span>Nouvel partenaire</span>
//             </div>
//           </div>
//           <div className="w-full flex justify-start">
//             <div className="h-[600px] mt-6 w-full">
//               {loading ? (
//                 <Box
//                   display="flex"
//                   justifyContent="center"
//                   alignItems="center"
//                   height="100%"
//                 >
//                   <CircularProgress />
//                 </Box>
//               ) : (
//                 <DataGrid
//                   getRowId={(row) => row.id_bp}
//                   columns={columns}
//                   rows={Array.isArray(entities) ? entities : []}
//                   pageSize={10}
//                   hideFooterSelectedRowCount
//                   disableSelectionOnClick
//                   sx={customDataGridStyles}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       <Modal
//         open={openModal}
//         onClose={handleCloseModal}
//         aria-labelledby="modal-details"
//       >
//         <Box sx={modalStyle}>
//           <Typography
//             variant="h6"
//             component="h2"
//             sx={{ mb: 3, fontWeight: 600 }}
//           >
//             Détails de la Demande
//           </Typography>

//           {selectedRequest && (
//             <Box sx={{ mb: 3 }}>
//               <Box sx={detailRowStyle}>
//                 <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
//                   Prénom:
//                 </Typography>
//                 <Typography>{selectedRequest.prenom}</Typography>
//               </Box>

//               <Box sx={detailRowStyle}>
//                 <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
//                   Nom:
//                 </Typography>
//                 <Typography>{selectedRequest.nom}</Typography>
//               </Box>

//               <Box sx={detailRowStyle}>
//                 <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
//                   Email:
//                 </Typography>
//                 <Typography sx={{ wordBreak: "break-all" }}>
//                   {selectedRequest.email}
//                 </Typography>
//               </Box>

//               <Box sx={detailRowStyle}>
//                 <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
//                   Téléphone:
//                 </Typography>
//                 <Typography>{selectedRequest.numero_de_telephone}</Typography>
//               </Box>

//               <Box sx={detailRowStyle}>
//                 <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
//                   Message:
//                 </Typography>
//                 <Typography>{selectedRequest.message}</Typography>
//               </Box>
//             </Box>
//           )}

//           <Box
//             sx={{
//               display: "flex",
//               gap: 2,
//               justifyContent: "flex-end",
//               mt: 3,
//             }}
//           >
//             <Button variant="outlined" onClick={handleCloseModal}>
//               Fermer
//             </Button>
//             <Button
//               variant="contained"
//               onClick={handleAcceptRequest}
//               sx={{
//                 backgroundColor: "#1976d2",
//                 "&:hover": {
//                   backgroundColor: "#1565c0",
//                 },
//               }}
//             >
//               Accepter la demande
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default BpDemandes;

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API_GATEWAY_URL } from "../constants";
import AdminCard from "./admin/AdminCard";
import {
  Checkbox,
  Modal,
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BpDemandes = () => {
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openNewPartnerModal, setOpenNewPartnerModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    numero_de_telephone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_GATEWAY_URL}/businesspartnerrequests/`
        );
        setEntities(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données : ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenModal = (request) => {
    setSelectedRequest(request);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRequest(null);
  };

  const handleNewPartnerModal = () => {
    setOpenNewPartnerModal(true);
  };

  const handleCloseNewPartnerModal = () => {
    setOpenNewPartnerModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Données du formulaire :", formData);
    handleCloseNewPartnerModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, numero_de_telephone: value }));
  };

  const handleAcceptRequest = () => {
    console.log("Demande acceptée : ", selectedRequest);
    handleCloseModal();
  };

  const columns = [
    {
      field: "select",
      headerName: "Sélectionné",
      width: 80,
      renderCell: (params) => (
        <Checkbox
          checked={selectedEntities === params.row.id_bp}
          sx={{
            "&.Mui-checked": {
              color: "#1976d2",
            },
          }}
        />
      ),
    },
    { field: "id_bp", headerName: "ID", width: 150 },
    { field: "prenom", headerName: "Prénom", width: 150 },
    { field: "nom", headerName: "Nom", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "numero_de_telephone",
      headerName: "Téléphone",
      width: 150,
    },
    {
      field: "details",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#1976d2",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={() => handleOpenModal(params.row)}
        >
          Détails
        </Button>
      ),
    },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const detailRowStyle = {
    display: "grid",
    gridTemplateColumns: "120px 1fr",
    gap: "16px",
    marginBottom: "16px",
  };

  return (
    <>
      <AdminCard />
      <div className="w-full flex justify-center items-center">
        <div className="w-[75%] flex flex-col items-center justify-center ml-[18%]">
          <div className="w-full flex justify-start">
            <h2 className="text-[#10316B] font-poppins font-bold text-2xl mt-16">
              Demandes BPs
            </h2>
          </div>
          <div className="w-full flex justify-end space-x-2 mt-8">
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewPartnerModal}
            >
              Nouvel partenaire
            </Button>
          </div>
          <div className="w-full flex justify-start">
            <div className="h-[600px] mt-6 w-full">
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <DataGrid
                  getRowId={(row) => row.id_bp}
                  columns={columns}
                  rows={Array.isArray(entities) ? entities : []}
                  pageSize={10}
                  hideFooterSelectedRowCount
                  disableSelectionOnClick
                  // sx={customDataGridStyles}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-details"
      >
        <Box sx={modalStyle}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 3, fontWeight: 600 }}
          >
            Détails de la Demande
          </Typography>

          {selectedRequest && (
            <Box sx={{ mb: 3 }}>
              <Box sx={detailRowStyle}>
                <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
                  Prénom:
                </Typography>
                <Typography>{selectedRequest.prenom}</Typography>
              </Box>

              <Box sx={detailRowStyle}>
                <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
                  Nom:
                </Typography>
                <Typography>{selectedRequest.nom}</Typography>
              </Box>

              <Box sx={detailRowStyle}>
                <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
                  Email:
                </Typography>
                <Typography sx={{ wordBreak: "break-all" }}>
                  {selectedRequest.email}
                </Typography>
              </Box>

              <Box sx={detailRowStyle}>
                <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
                  Téléphone:
                </Typography>
                <Typography>{selectedRequest.numero_de_telephone}</Typography>
              </Box>

              <Box sx={detailRowStyle}>
                <Typography sx={{ fontWeight: 500, color: "#4B5563" }}>
                  Message:
                </Typography>
                <Typography>{selectedRequest.message}</Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
              mt: 3,
            }}
          >
            <Button variant="outlined" onClick={handleCloseModal}>
              Fermer
            </Button>
            <Button
              variant="contained"
              onClick={handleAcceptRequest}
              sx={{
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Accepter la demande
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openNewPartnerModal}
        onClose={handleCloseNewPartnerModal}
        aria-labelledby="modal-partnership"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "90%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{
              mb: 2,
              fontSize: "1.2rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Nouvel Partenaire
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                gap: 2,
                mb: 2,
              }}
            >
              <input
                type="text"
                name="nom"
                placeholder="Nom"
                value={formData.nom}
                onChange={handleChange}
                style={{
                  height: "40px",
                  padding: "0 12px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  fontSize: "0.9rem",
                  width: "100%",
                }}
              />
              <input
                type="text"
                name="prenom"
                placeholder="Prénom"
                value={formData.prenom}
                onChange={handleChange}
                style={{
                  height: "40px",
                  padding: "0 12px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  fontSize: "0.9rem",
                  width: "100%",
                }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleChange}
                style={{
                  height: "40px",
                  padding: "0 12px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  fontSize: "0.9rem",
                  width: "100%",
                }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <PhoneInput
                country="fr"
                onlyCountries={["fr"]}
                value={formData.numero_de_telephone}
                onChange={handlePhoneChange}
                containerStyle={{
                  width: "100%",
                }}
                inputStyle={{
                  height: "40px",
                  width: "100%",
                  fontSize: "0.9rem",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <textarea
                name="message"
                placeholder="Votre message..."
                value={formData.message}
                onChange={handleChange}
                style={{
                  height: "100px",
                  padding: "12px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  fontSize: "0.9rem",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                height: "40px",
                textTransform: "none",
                fontSize: "0.9rem",
                borderRadius: "8px",
              }}
            >
              Envoyer
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default BpDemandes;
